<template>
  <!-- Table Container Card -->
  <section class="invoice-preview-wrapper">
    <b-row
      class="invoice-preview"
    >
      <b-card
        no-body
        class="invoice-preview-card"
      />
    </b-row>
  </section>

</template>

<script>
import {
  VBToggle, BRow, BCard, // BCardTitle,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Swal from 'sweetalert2'
// import SidebarAddNewTicket from './sidebar/SidebarAddNewTicket.vue'

export default {
  components: {
    BRow,
    BCard,
    // BCardTitle,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style>
  .board-container {
    overflow-x: auto; /* Enables horizontal scrolling for the container */
    margin-right: 2vh;
    /* white-space: nowrap; */
  }
</style>
<style lang="scss">

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
